import axios from "axios";
const apiURL = process.env.REACT_APP_API_URL;

// Function to create a PayPal order
export const createPayPalOrder = async (amountTotal) => {
  try {
    let res = await axios.post(`${apiURL}/api/paypal/create-order`, { amountTotal });
    return res.data;
  } catch (error) {
    console.error(error);
  }
};

// Function to capture a PayPal order after the user approves it
export const capturePayPalOrder = async (paymentId, payerId) => {
  try {
    let res = await axios.post(`${apiURL}/api/paypal/capture-order/${paymentId}?PayerID=${payerId}`);
    return res.data;
  } catch (error) {
    console.error(error);
  }
};

// Function to create a new order in your system
export const createOrder = async (orderData) => {
  try {
    let res = await axios.post(`${apiURL}/api/order/create-order`, orderData);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

// Function to cancel a PayPal order
export const cancelOrder = async (orderId) => {
  try {
    let res = await axios.post(`${apiURL}/api/paypal/cancel-order/${orderId}`);
    return res.data;
  } catch (error) {
    console.error(error);
  }
};
