import React, { Fragment, useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LayoutContext } from "../layout";
import { subTotal, quantity, totalCost } from "../partials/Mixins";
import { cartListProduct } from "../partials/FetchApi";
import { fetchData, fetchPayPal, cancelPayPalOrder } from "./Action";

const apiURL = process.env.REACT_APP_API_URL;

export const CheckoutComponent = (props) => {
  const { data, dispatch } = useContext(LayoutContext);

  const [state, setState] = useState({
    name: "",
    address: "",
    phone: "",
    state: "",
    city: "",
    country: "",
    postalCode: "",
    error: false,
    success: false,
    clientToken: null,
    approvalLink: null,
  });

  useEffect(() => {
    fetchData(cartListProduct, dispatch);
    fetchPayPal(totalCost, setState);

    // Cleanup function to cancel order if user leaves the page
    return () => {
      if (state.clientToken) {
        cancelPayPalOrder(state.clientToken);
      }
    };
  }, [dispatch]);

  const isFormValid = () => {
    return state.name && state.address && state.phone && state.country && state.postalCode && state.city;
  };

  const handlePayNow = async () => {
    if (!isFormValid()) {
      setState({ ...state, error: "Please fill in all fields" });
      return;
    } else if (state.approvalLink) {
      window.location.href = state.approvalLink;
    }

    // Save order details to local storage
    localStorage.setItem('orderDetails', JSON.stringify({
      address: JSON.stringify({
        name: state.name,
        country: state.country,
        state: state.state,
        postalCode: state.postalCode,
        adress: state.address,
        city: state.city
      }),
      phone: state.phone,
    }));
  };

  // variable to check if products arent disabled and quantity is greater quantity to be ordered
  const isDisabled = (products) => {
    let disabled = false;
    products.forEach((product) => {
      if (product.pQuantity < quantity(product._id) || product.pStatus === "Disabled") {
        disabled = true;
      }
    });
    return disabled;
  }

  if (data.loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <svg
          className="w-12 h-12 animate-spin text-gray-600"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
          ></path>
        </svg>
        Please wait until finish
      </div>
    );
  }

  return (
    <Fragment>
      <section className="mx-4 mt-20 md:mx-12 md:mt-32 lg:mt-24">
        <div className="text-2xl mx-2">Order</div>
        {/* Product List */}
        <div className="flex flex-col-reverse md:flex md:space-x-2 md:flex-row">
          <div>
            <CheckoutProducts products={data.cartProduct} />
          </div>
          <div className="w-full order-first md:order-last md:w-1/2">
            {state.clientToken !== null ? (
              <Fragment>
                <div
                    onBlur={(e) => setState({...state, error: false})}
                    className="p-4 md:p-8"
                >
                  {state.error ? (
                      <div className="bg-red-200 py-2 px-4 rounded">
                        {state.error}
                      </div>
                  ) : (
                      ""
                  )}
                  <div className="flex flex-col py-2">
                    <label htmlFor="name" className="pb-2">
                      Name
                    </label>
                    <input
                        value={state.name}
                        onChange={(e) =>
                            setState({
                              ...state,
                              name: e.target.value,
                              error: false,
                            })
                        }
                        type="text"
                        id="name"
                        className="border px-4 py-2"
                        placeholder="Name..."
                    />
                  </div>
                  <div className="flex flex-col py-2 mb-2">
                    <label htmlFor="phone" className="pb-2">
                      Phone
                    </label>
                    <input
                        value={state.phone}
                        onChange={(e) =>
                            setState({
                              ...state,
                              phone: e.target.value,
                              error: false,
                            })
                        }
                        type="number"
                        id="phone"
                        className="border px-4 py-2"
                        placeholder="+880"
                    />
                  </div>
                  <div className="flex flex-col py-2">
                    <label htmlFor="country" className="pb-2">
                      Country
                    </label>
                    <input
                        value={state.country}
                        onChange={(e) =>
                            setState({
                              ...state,
                              country: e.target.value,
                              error: false,
                            })
                        }
                        type="text"
                        id="country"
                        className="border px-4 py-2"
                        placeholder="Country..."
                    />
                  </div>
                  <div className="flex flex-col py-2 mb-2">
                    <label htmlFor="state" className="pb-2">
                      State
                    </label>
                    <input
                        value={state.state}
                        onChange={(e) =>
                            setState({
                              ...state,
                              state: e.target.value,
                              error: false,
                            })
                        }
                        type="text"
                        id="state"
                        className="border px-4 py-2"
                        placeholder="State..."
                    />
                  </div>
                  <div className="flex flex-col py-2">
                    <label htmlFor="city" className="pb-2">
                      City
                    </label>
                    <input
                        value={state.city}
                        onChange={(e) =>
                            setState({
                              ...state,
                              city: e.target.value,
                              error: false,
                            })
                        }
                        type="text"
                        id="city"
                        className="border px-4 py-2"
                        placeholder="City..."
                    />
                  </div>
                  <div className="flex flex-col py-2 mb-2">
                    <label htmlFor="postalCode" className="pb-2">
                      Postal Code
                    </label>
                    <input
                        value={state.postalCode}
                        onChange={(e) =>
                            setState({
                              ...state,
                              postalCode: e.target.value,
                              error: false,
                            })
                        }
                        type="text"
                        id="postalCode"
                        className="border px-4 py-2"
                        placeholder="Postal Code..."
                    />
                  </div>
                  <div className="flex flex-col py-2">
                    <label htmlFor="address" className="pb-2">
                      Delivery Address
                    </label>
                    <input
                        value={state.address}
                        onChange={(e) =>
                            setState({
                              ...state,
                              address: e.target.value,
                              error: false,
                            })
                        }
                        type="text"
                        id="address"
                        className="border px-4 py-2"
                        placeholder="Address..."
                    />
                  </div>
                    { isDisabled(data.cartProduct) ? (
                        <span
                            className="text-red-500 text-sm"
                        >
                            Some of your products arent in stock or quantity is less than you want to order
                        </span>
                    ) : null}
                    <div
                        onClick={handlePayNow}
                        className={`w-full px-4 py-2 text-center text-white font-semibold cursor-pointer ${!isFormValid() ? "opacity-50 cursor-not-allowed" : ""}`}
                      style={{background: "#303031"}}
                      disabled={!isFormValid() || isDisabled(data.cartProduct)}
                  >
                    Pay now
                  </div>
                </div>
              </Fragment>
            ) : (
                <div className="flex items-center justify-center py-12">
                  <svg
                      className="w-12 h-12 animate-spin text-gray-600"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                    ></path>
                </svg>
              </div>
            )}
          </div>
        </div>
      </section>
    </Fragment>
  );
};

const CheckoutProducts = ({ products }) => {
  const navigate = useNavigate();

  return (
    <Fragment>
      <div className="grid grid-cols-1">
        {products !== null && products.length > 0 ? (
          products.map((product, index) => {
            return (
              <div
                onClick={(e) => navigate(`/products/${product._id}`)}
                key={index}
                className="cursor-pointer col-span-1 m-2 p-4 border-t border-b my-2 mx-0 flex items-center justify-between overflow-auto"
              >
                <div className="flex items-center space-x-4">
                  <img
                    className="h-20 w-20 object-cover object-center"
                    src={`${apiURL}/uploads/products/${product.pImages[0]}`}
                    alt="wishListproduct"
                  />
                  <div className="text-lg ml-6 truncate">
                    {product.pName}
                  </div>
                  <div className="ml-6 font-semibold text-gray-600 text-sm">
                    Price : ${product.pPrice}.00{" "}
                  </div>
                  <div className="ml-6 font-semibold text-gray-600 text-sm">
                    Quantity : {quantity(product._id)}
                  </div>
                  <div className="font-semibold text-gray-600 text-sm">
                    Subtotal : ${subTotal(product._id, product.pPrice)}.00
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div>No product found for checkout</div>
        )}
        <div className="font-semibold text-gray-600 text-sm mt-4 flex justify-end">
          <span className="p-4">
            Total: ${totalCost()}.00
          </span>
        </div>
      </div>
    </Fragment>
  );
};

export default CheckoutProducts;
