import React from "react";
import { Navigate } from "react-router-dom";
import { isAuthenticate } from "./fetchApi";

const CartProtectedRoute = ({ children }) => {
  const isCartNotEmpty = JSON.parse(localStorage.getItem("cart")).length !== 0;
  const isAuthenticated = isAuthenticate();

  if (!isCartNotEmpty || !isAuthenticated) {
    return <Navigate to="/" />;
  }

  return children;
};

export default CartProtectedRoute;
