import React from "react";
import Layout from "../layout"; // Import the Layout component

const TermsOfServiceComponent = () => {
    return (
        <div className="container mx-auto p-4 mt-24">
            <h1 className="text-2xl font-bold mb-4">Terms of Service</h1>
            <p className="mb-2">By accessing or using our website (the "Zyppin"), you agree to comply with and be bound by these Terms of Service ("Terms"). If you do not agree to these Terms, please do not use our Site.</p>

            <h2 className="text-xl font-bold mb-2">Changes to Terms</h2>
            <p className="mb-2">We reserve the right to modify these Terms at any time. Changes will be effective immediately upon posting on our Site. Your continued use of the Site after any changes constitutes your acceptance of the new Terms.</p>

            <h2 className="text-xl font-bold mb-2">User Responsibilities</h2>
            <p className="mb-2"><strong>Account Creation:</strong> To access certain features, you may need to create an account. You must provide accurate and complete information during registration and keep your account information up-to-date. You are responsible for maintaining the confidentiality of your account credentials.</p>
            <p className="mb-2"><strong>Prohibited Conduct:</strong> You agree not to engage in any unlawful or harmful activities on our Site, including but not limited to posting inappropriate content, harassment, or attempting to disrupt the Site’s functionality.</p>

            <h2 className="text-xl font-bold mb-2">Services Provided</h2>
            <p className="mb-2"><strong>Products and Orders:</strong> We provide an online platform where you can purchase products. All orders are subject to acceptance and availability.</p>
            <p className="mb-2"><strong>Service Availability:</strong> We strive to keep our Site operational but do not guarantee that the Site will be available at all times. We reserve the right to suspend or terminate services for maintenance or other reasons.</p>

            <h2 className="text-xl font-bold mb-2">Payments</h2>
            <p className="mb-2"><strong>Payment Methods:</strong> We accept payments through PayPal and other methods as specified on our Site.</p>
            <p className="mb-2"><strong>Fees and Charges:</strong> All fees and charges are outlined on our Site and are subject to change. Any additional charges, including shipping fees, will be communicated to you during the checkout process.</p>

            <h2 className="text-xl font-bold mb-2">Shipping and Delivery</h2>
            <p className="mb-2"><strong>Domestic and International Shipping:</strong> We offer both domestic and international shipping. Shipping fees, delivery times, and available destinations will be provided at checkout. International orders may be subject to customs duties and taxes, which are the responsibility of the customer.</p>
            <p className="mb-2"><strong>Order Processing:</strong> Orders are processed within 4 business days. Shipping times vary based on the destination and shipping method.</p>
            <p className="mb-2"><strong>Tracking:</strong> You will receive a tracking number once your order has been shipped. You can use this tracking number to check the status of your delivery.</p>
            <p className="mb-2"><strong>Delivery Issues:</strong> We are not responsible for delays caused by customs, postal services, or other factors outside our control.</p>

            <h2 className="text-xl font-bold mb-2">Refunds and Cancellations</h2>
            <p className="mb-2"><strong>Refund Policy:</strong> If you are not satisfied with your purchase, you may request a refund within 14 days of receiving your order. To initiate a refund, please contact us at {process.env.REACT_APP_CONTACT_EMAIL} or {process.env.REACT_APP_CONTACT_PHONE}. Please note that the item must be returned in its original condition and packaging. Shipping fees are non-refundable, and you are responsible for return shipping costs.</p>
            <p className="mb-2"><strong>Order Cancellations:</strong> You may cancel your order before it has been shipped. To cancel an order, please contact us at {process.env.REACT_APP_CONTACT_EMAIL} or {process.env.REACT_APP_CONTACT_PHONE} as soon as possible. Once an order has been shipped, it cannot be canceled, but you may request a return and refund according to our Refund Policy.</p>

            <h2 className="text-xl font-bold mb-2">Intellectual Property</h2>
            <p className="mb-2"><strong>Ownership:</strong> All content on the Site, including text, graphics, logos, and images, is owned by or licensed to us. You may not use, reproduce, or distribute any content without our prior written consent.</p>

            <h2 className="text-xl font-bold mb-2">Privacy and Data Protection</h2>
            <p className="mb-2"><strong>Privacy Policy:</strong> Your use of the Site is also governed by our Privacy Policy, which explains how we collect, use, and protect your personal information. Please review our Privacy Policy for details.</p>

            <h2 className="text-xl font-bold mb-2">Limitation of Liability</h2>
            <p className="mb-2"><strong>Disclaimer:</strong> The Site and its contents are provided "as is" without warranties of any kind. We disclaim all warranties, express or implied, to the fullest extent permitted by law.</p>
            <p className="mb-2"><strong>Liability:</strong> We are not liable for any indirect, incidental, or consequential damages arising from your use of the Site.</p>

            <h2 className="text-xl font-bold mb-2">Dispute Resolution</h2>
            <p className="mb-2"><strong>Governing Law:</strong> These Terms are governed by the laws of Germany. Any disputes will be resolved in the courts located in Herbstein, Hesse, 36358.</p>
            <p className="mb-2"><strong>Resolution Process:</strong> Disputes should be resolved through informal negotiations. If unresolved, disputes may be submitted to mediation or arbitration.</p>

            <h2 className="text-xl font-bold mb-2">Termination</h2>
            <p className="mb-2"><strong>Termination by Us:</strong> We reserve the right to suspend or terminate your access to the Site at our discretion, including for violations of these Terms.</p>
            <p className="mb-2"><strong>Termination by You:</strong> You may terminate your account by contacting us through the information provided on our Site.</p>

            <h2 className="text-xl font-bold mb-2">Third-Party Links</h2>
            <p className="mb-2"><strong>External Sites:</strong> Our Site may contain links to third-party websites. We are not responsible for the content or privacy practices of these external sites.</p>

            <h2 className="text-xl font-bold mb-2">International Data Transfers</h2>
            <p className="mb-2"><strong>Data Transfers:</strong> We may transfer personal data internationally in order to provide our services. When we do so, we ensure that appropriate safeguards are in place to protect your personal information.</p>

            <h2 className="text-xl font-bold mb-2">Contact Us</h2>
            <p className="mb-2">If you have any questions or concerns about these Terms, please contact us:</p>
            <p className="mb-2"><strong>Email:</strong> {process.env.REACT_APP_CONTACT_EMAIL}</p>
            <p className="mb-2"><strong>Phone:</strong> {process.env.REACT_APP_CONTACT_PHONE}</p>
            <p className="mb-2"><strong>Location:</strong> {process.env.REACT_APP_COMPANY_LOCATION}</p>
        </div>
    );
};

const TermsOfService = () => {
    return (
        <Layout>
            <TermsOfServiceComponent />
        </Layout>
    );
};

export default TermsOfService;
