import React from "react";
import Layout from "../layout"; // Import the Layout component

const PrivacyPolicyComponent = () => {
    return (
        <div className="container mx-auto p-4 mt-24">
            <h1 className="text-2xl font-bold mb-4">Privacy Policy</h1>
            <p className="mb-2">Effective Date: July 18, 2024</p>
            <p className="mb-2">Zyppin ("we", "our", "us") respects your privacy and is committed to protecting your personal information. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website.</p>

            <h2 className="text-xl font-bold mb-2">1. Information We Collect</h2>
            <p className="mb-2">We collect personal information that you voluntarily provide to us when you register on the website, place an order, or contact us. The personal information we collect includes:</p>
            <ul className="list-disc pl-6 mb-2">
                <li><strong>User Information:</strong> userImage, history (order history), name, email, password</li>
                <li><strong>Order Information:</strong> amount, transactionId, address (including name, country, state, postal code), phone number</li>
            </ul>

            <h2 className="text-xl font-bold mb-2">2. How We Use Your Information</h2>
            <p className="mb-2">We use the information we collect in the following ways:</p>
            <ul className="list-disc pl-6 mb-2">
                <li><strong>To Provide and Manage Our Services:</strong> We use your information to create and manage your account, process orders, facilitate shipping, and provide customer support.</li>
                <li><strong>To Communicate with You:</strong> We use your information to send notifications, respond to inquiries, and provide updates related to your orders and our services.</li>
                <li><strong>For Authorization and Security:</strong> We use your information to verify your identity, prevent fraud, and secure our platform.</li>
            </ul>

            <h2 className="text-xl font-bold mb-2">3. Sharing Your Information</h2>
            <p className="mb-2">We do not share your personal information with third parties except as necessary to provide our services (e.g., shipping providers) or as required by law.</p>

            <h2 className="text-xl font-bold mb-2">4. Cookies and Tracking Technologies</h2>
            <p className="mb-2">We do not use cookies or other tracking technologies on our website.</p>

            <h2 className="text-xl font-bold mb-2">5. Data Security</h2>
            <p className="mb-2">We use various security measures, including JWT (JSON Web Tokens), bcrypt for password hashing, and time-limited tokens, to protect your personal information from unauthorized access, use, or disclosure.</p>

            <h2 className="text-xl font-bold mb-2">6. Your Rights</h2>
            <p className="mb-2">You have the following rights regarding your personal information:</p>
            <ul className="list-disc pl-6 mb-2">
                <li><strong>Access:</strong> You can request access to the personal information we hold about you.</li>
                <li><strong>Edit:</strong> You can update or correct your personal information.</li>
                <li><strong>Delete:</strong> You can request the deletion of your personal information by contacting us.</li>
            </ul>

            <h2 className="text-xl font-bold mb-2">7. Children's Privacy</h2>
            <p className="mb-2">Our website is accessible to individuals of all ages. However, our payment system (PayPal) requires users to be of legal age to make purchases. We do not knowingly collect personal information from children under the age of 13.</p>

            <h2 className="text-xl font-bold mb-2">8. Policy Changes</h2>
            <p className="mb-2">We may update this Privacy Policy from time to time. We will notify you of any changes via email or by posting the updated policy on our website.</p>

            <h2 className="text-xl font-bold mb-2">9. Third-Party Links</h2>
            <p className="mb-2">Our website contains links to social media platforms such as Instagram, Facebook, and TikTok. We are not responsible for the privacy practices of these third-party websites.</p>

            <h2 className="text-xl font-bold mb-2">10. International Data Transfers</h2>
            <p className="mb-2">We may transfer personal data internationally in order to provide our services. When we do so, we ensure that appropriate safeguards are in place to protect your personal information.</p>

            <h2 className="text-xl font-bold mb-2">11. Contact Us</h2>
            <p className="mb-2">If you have any questions or concerns about this Privacy Policy, please contact us:</p>
            <ul className="list-disc pl-6 mb-2">
                <li><strong>Email:</strong> {process.env.REACT_APP_CONTACT_EMAIL}</li>
                <li><strong>Phone:</strong> {process.env.REACT_APP_CONTACT_PHONE}</li>
                <li><strong>Location:</strong> {process.env.REACT_APP_COMPANY_LOCATION}</li>
            </ul>
        </div>
    );
};

const PrivacyPolicy = () => {
    return (
        <Layout>
            <PrivacyPolicyComponent />
        </Layout>
    );
};

export default PrivacyPolicy;
