import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { capturePayPalOrder, createOrder } from './FetchApi';

const SuccessPage = () => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const paymentId = queryParams.get('paymentId');
    const PayerID = queryParams.get('PayerID');

    useEffect(() => {
        if (paymentId && PayerID) {
            handlePayment();
        } else {
            setError('Invalid payment details');
            setLoading(false);
        }
    }, [paymentId, PayerID]);

    const handlePayment = async () => {
        try {
            // Capture the PayPal order
            const captureResponse = await capturePayPalOrder(paymentId, PayerID);

            if (captureResponse) {
                // Prepare order data
                const orderData = {
                    allProduct: JSON.parse(localStorage.getItem('cart')),
                    user: JSON.parse(localStorage.getItem('jwt')).user._id,
                    amount: captureResponse.transactions[0].amount.total,
                    transactionId: captureResponse.id,
                    address: JSON.parse(localStorage.getItem('orderDetails')).address,
                    phone: JSON.parse(localStorage.getItem('orderDetails')).phone,
                };

                // Create the order in your system
                const orderResponse = await createOrder(orderData);

                if (orderResponse.success) {
                    localStorage.setItem('cart', JSON.stringify([]));
                    localStorage.removeItem('orderDetails');
                    navigate('/user/orders'); // Redirect to a success page or homepage
                } else {
                    setError('Order creation failed');
                }
            } else {
                setError('Payment capture failed');
            }
        } catch (err) {
            setError('An error occurred, try to reload page');
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="flex items-center justify-center h-screen">
            {loading ? (
                <div>Loading...</div>
            ) : error ? (
                <div>Error: {error}</div>
            ) : (
                <div>Payment successful. Redirecting...</div>
            )}
        </div>
    );
};

export default SuccessPage;
