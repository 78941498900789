import React from "react";
import Layout from "../layout";

const ContactUsComponent = () => {
    return (
        <div className="container mx-auto p-4 mt-24">
            <h1 className="text-2xl font-bold mb-4">Contact Us</h1>
            {/*<p className="mb-2">We'd love to hear from you! Please fill out the form below or contact us at:</p>*/}
            <p>We'd love to hear from you! Please contact us at:</p>
            <p>Email: {process.env.REACT_APP_CONTACT_EMAIL}</p>
            <p>Phone: {process.env.REACT_APP_CONTACT_PHONE}</p>

            {/*<form className="mt-4">*/}
            {/*    <div className="mb-4">*/}
            {/*        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">*/}
            {/*            Name*/}
            {/*        </label>*/}
            {/*        <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="name" type="text" placeholder="Your Name"/>*/}
            {/*    </div>*/}
            {/*    <div className="mb-4">*/}
            {/*        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">*/}
            {/*            Email*/}
            {/*        </label>*/}
            {/*        <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="email" type="email" placeholder="Your Email"/>*/}
            {/*    </div>*/}
            {/*    <div className="mb-4">*/}
            {/*        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="message">*/}
            {/*            Message*/}
            {/*        </label>*/}
            {/*        <textarea className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="message" placeholder="Your Message" rows="5"></textarea>*/}
            {/*    </div>*/}
            {/*    <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"*/}
            {/*    style={{ background: "#303031" }}*/}
            {/*    type="button">*/}
            {/*        Send*/}
            {/*    </button>*/}
            {/*</form>*/}
        </div>
    );
};


const ContactUs = () => {
    return (
        <Layout>
            <ContactUsComponent />
        </Layout>
    );
};

export default ContactUs;
