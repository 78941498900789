import React, { useState } from "react";
import axios from "axios";
const apiURL = process.env.REACT_APP_API_URL;

const ForgotPassword = ({ backToLogin }) => {
    const [step, setStep] = useState(1); // 1: Request email, 2: Enter code and new password
    const [email, setEmail] = useState("");
    const [code, setCode] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [repeatPassword, setRepeatPassword] = useState("");
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");

    const handleRequestCode = async () => {
        try {
            const response = await axios.post(`${apiURL}/api/password-reset/request-reset-code`, { email });
            if (response.data.success) {
                setStep(2);
                setMessage("A reset code has been sent to your email.");
                setError(""); // Clear error if the request was successful
            } else {
                setError(response.data.error);
            }
        } catch (err) {
            setError("An error occurred. Please try again.");
        }
    };

    const handleResetPassword = async () => {
        if (newPassword !== repeatPassword) {
            setError("Passwords do not match.");
            return;
        }

        try {
            const response = await axios.post(`${apiURL}/api/password-reset/reset-password`, { email, code, newPassword });
            if (response.data.success) {
                setMessage("Password reset successfully. You can now log in.");
                setStep(1);
                setEmail("");
                setCode("");
                setNewPassword("");
                setRepeatPassword("");
                setError(""); // Clear error if the request was successful
            } else {
                setError(response.data.error);
            }
        } catch (err) {
            setError("An error occurred. Please try again.");
        }
    };

    return (
        <div className="p-4">
            {message && <div className="text-green-500">{message}</div>}
            {error && <div className="text-red-500">{error}</div>}
            {step === 1 && (
                <div>
                    <h2 className="text-xl mb-4">Forgot Password</h2>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => {
                            setEmail(e.target.value);
                            setError(""); // Clear error on input change
                        }}
                        placeholder="Enter your email"
                        className="px-4 py-2 border mb-2 w-full"
                    />
                    <button
                        onClick={handleRequestCode}
                        style={{ background: "#303031" }}
                        className="font-medium px-4 py-2 text-white text-center cursor-pointer w-full mb-2"
                    >
                        Send Reset Code
                    </button>
                    <button
                        onClick={backToLogin}
                        style={{ background: "#303031" }}
                        className="font-medium px-4 py-2 text-white text-center cursor-pointer w-full"
                    >
                        Back to Login
                    </button>
                </div>
            )}
            {step === 2 && (
                <div>
                    <h2 className="text-xl mb-4">Reset Password</h2>
                    <input
                        type="text"
                        value={code}
                        onChange={(e) => {
                            setCode(e.target.value);
                            setError(""); // Clear error on input change
                        }}
                        placeholder="Enter reset code"
                        className="px-4 py-2 border mb-2 w-full"
                    />
                    <input
                        type="password"
                        value={newPassword}
                        onChange={(e) => {
                            setNewPassword(e.target.value);
                            setError(""); // Clear error on input change
                        }}
                        placeholder="Enter new password"
                        className="px-4 py-2 border mb-2 w-full"
                    />
                    <input
                        type="password"
                        value={repeatPassword}
                        onChange={(e) => {
                            setRepeatPassword(e.target.value);
                            setError(""); // Clear error on input change
                        }}
                        placeholder="Repeat new password"
                        className="px-4 py-2 border mb-2 w-full"
                    />
                    <button
                        onClick={handleResetPassword}
                        style={{ background: "#303031" }}
                        className="font-medium px-4 py-2 text-white text-center cursor-pointer w-full mb-2"
                    >
                        Save New Password
                    </button>
                    <button
                        onClick={backToLogin}
                        style={{ background: "#303031" }}
                        className="font-medium px-4 py-2 text-white text-center cursor-pointer w-full"
                    >
                        Back to Login
                    </button>
                </div>
            )}
        </div>
    );
};

export default ForgotPassword;
