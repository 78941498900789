import { createOrder, createPayPalOrder, capturePayPalOrder, cancelOrder } from "./FetchApi";

export const fetchData = async (cartListProduct, dispatch) => {
  dispatch({ type: "loading", payload: true });
  try {
    let responseData = await cartListProduct();
    if (responseData && responseData.Products) {
      setTimeout(function () {
        dispatch({ type: "cartProduct", payload: responseData.Products });
        dispatch({ type: "loading", payload: false });
      }, 1000);
    }
  } catch (error) {
    console.log(error);
  }
};

export const fetchPayPal = async (totalCost, setState) => {
  try {
    let responseData = await createPayPalOrder(totalCost());
    if (responseData) {
      setState({
        clientToken: responseData.id,
        success: true,
        approvalLink: responseData.links.find(link => link.rel === "approval_url").href,
      });
      console.log(responseData);
    }
  } catch (error) {
    console.log(error);
  }
};

export const cancelPayPalOrder = async (orderId) => {
  try {
    await cancelOrder(orderId);
  } catch (error) {
    console.log(error);
  }
};
