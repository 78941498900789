import React from "react";
import Layout from "../layout"; // Import the Layout component

const AboutUsComponent = () => {
    return (
        <div className="container mx-auto p-4 mt-24">
            <h1 className="text-2xl font-bold mb-4">About Us</h1>
            <p className="mb-2">Welcome to Zyppin. We are dedicated to providing the best service to our customers.</p>
            <p className="mb-2">
                Our mission is to create a seamless shopping experience for our customers by offering high-quality products, exceptional customer service, and a user-friendly platform. We strive to exceed expectations and ensure customer satisfaction at every step.
            </p>
            <p className="mb-2">
                Our values include integrity, customer focus, innovation, and excellence. We believe in conducting our business with the highest ethical standards, putting our customers at the heart of everything we do, constantly innovating to improve our services, and pursuing excellence in every aspect of our operations.
            </p>
            <p className="mb-2">
                Our team is comprised of experts in e-commerce, logistics, customer service, and technology. With a diverse range of skills and a shared commitment to our mission, we work collaboratively to deliver the best possible experience for our customers.
            </p>
            <p className="mb-2">
                Contact us for more information. We are here to help and look forward to hearing from you.
            </p>
            <h2 className="text-xl font-bold mb-2">Contact Information</h2>
            <p className="mb-2"><strong>Email:</strong> {process.env.REACT_APP_CONTACT_EMAIL}</p>
            <p className="mb-2"><strong>Phone:</strong> {process.env.REACT_APP_CONTACT_PHONE}</p>
            <p className="mb-2"><strong>Address:</strong> {process.env.REACT_APP_COMPANY_LOCATION}</p>
        </div>
    );
};

const AboutUs = () => {
    return (
        <Layout>
            <AboutUsComponent />
        </Layout>
    );
};

export default AboutUs;
