import React from "react";
import Layout from "../layout"; // Import the Layout component

const CodeOfConductComponent = () => {
    return (
        <div className="container mx-auto p-4 mt-24">
            <h1 className="text-2xl font-bold mb-4">Code of Conduct</h1>
            <p className="mb-2">We are committed to providing a friendly, safe, and welcoming environment for all users of our website.</p>

            <h2 className="text-xl font-bold mb-2">Our Standards</h2>
            <p className="mb-2">Examples of behavior that contributes to creating a positive environment include:</p>
            <ul className="list-disc ml-6 mb-2">
                <li>Using welcoming and inclusive language</li>
                <li>Being respectful of differing viewpoints and experiences</li>
                <li>Gracefully accepting constructive criticism</li>
                <li>Focusing on what is best for the community</li>
                <li>Showing empathy towards other community members</li>
            </ul>

            <h2 className="text-xl font-bold mb-2">Unacceptable Behavior</h2>
            <p className="mb-2">Examples of unacceptable behavior by participants include:</p>
            <ul className="list-disc ml-6 mb-2">
                <li>The use of sexualized language or imagery and unwelcome sexual attention or advances</li>
                <li>Trolling, insulting or derogatory comments, and personal or political attacks</li>
                <li>Public or private harassment</li>
                <li>Publishing others' private information, such as a physical or electronic address, without explicit permission</li>
                <li>Other conduct which could reasonably be considered inappropriate in a professional setting</li>
            </ul>

            <h2 className="text-xl font-bold mb-2">Review and Comment Policy</h2>
            <p className="mb-2">When leaving a review or comment on our website, please adhere to the following guidelines:</p>
            <ul className="list-disc ml-6 mb-2">
                <li>Be respectful and considerate in your comments.</li>
                <li>Do not post offensive, abusive, or discriminatory content.</li>
                <li>Refrain from sharing personal information, including email addresses, within your comments.</li>
                <li>Constructive feedback is welcome, but avoid personal attacks or negative language.</li>
                <li>Your email address will not be published and is collected solely for verification purposes.</li>
            </ul>

            <h2 className="text-xl font-bold mb-2">Enforcement</h2>
            <p className="mb-2">Instances of abusive, harassing, or otherwise unacceptable behavior may be reported by contacting us. All complaints will be reviewed and investigated and will result in a response that is deemed necessary and appropriate to the circumstances. We are obligated to maintain confidentiality with regard to the reporter of an incident. Further details of specific enforcement policies may be posted separately.</p>
            <p className="mb-2">Participants who are asked to stop any harassing behavior are expected to comply immediately. If a participant engages in behavior that violates this Code of Conduct, we may take any action we deem appropriate, including warning the offender or expulsion from the platform with no refund.</p>

            <h2 className="text-xl font-bold mb-2">Contact Us</h2>
            <p className="mb-2">If you have any questions or concerns about this Code of Conduct, please contact us:</p>
            <p className="mb-2"><strong>Email:</strong> {process.env.REACT_APP_CONTACT_EMAIL}</p>
            <p className="mb-2"><strong>Phone:</strong> {process.env.REACT_APP_CONTACT_PHONE}</p>
            <p className="mb-2"><strong>Location:</strong> {process.env.REACT_APP_COMPANY_LOCATION}</p>
        </div>
    );
};

const CodeOfConduct = () => {
    return (
        <Layout>
            <CodeOfConductComponent />
        </Layout>
    );
};

export default CodeOfConduct;
