import React from "react";
import { Navigate } from "react-router-dom";
import { isAuthenticate, isAdmin } from "./fetchApi";

const AdminProtectedRoute = ({ children }) => {
  const isAuthenticated = isAuthenticate();
  const isAdminUser = isAdmin();

  if (!isAuthenticated || !isAdminUser) {
    return <Navigate to="/user/profile" />;
  }

  return children;
};

export default AdminProtectedRoute;
